import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";

const getIntegratedReportPreview = async (data_source, date, supply_partner_id, supply_tag_id) => {
    try {
        const query = `
                    query MyQuery {
                      integrated_report_preview(
                        where: {data_source: "${data_source}", date: "${date}", supply_partner_id: "${supply_partner_id}", supply_tag_id: "${supply_tag_id}"}
                      ) {
                        ads_supply_tag_id
                        ads_supply_tag_name
                        ads_total_ad_requests
                        ads_total_impressions
                        core_supply_tag_id
                        core_supply_tag_name
                        core_total_ad_requests
                        core_total_impressions
                        delivery_id
                      }
                    } `;

        const response = await axios.post(REPORT_BFF_URI, { query });
        return response.data.data.integrated_report_preview;
    } catch (error) {
        console.error("Error fetching Report View for Integrated STR:", error);
        return null;
    }
};

export default getIntegratedReportPreview;
