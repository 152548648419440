import React, { createContext, useState } from 'react';
import dayjs from "dayjs";
import {DAY_BEFORE_YESTERDAY} from "../Constant";

export const IntegratedReportContext = createContext();

export const IntegratedReportProvider = ({ children }) => {
    const [reportName, setReportName] = useState('');
    const [date, setDate] = useState(dayjs(DAY_BEFORE_YESTERDAY));
    const [dataSource, setDataSource] = useState('SS Core');
    const [supplyPartnerID, setSupplyPartnerID] = useState('');
    const [supplyTagID, setSupplyTagID] = useState('');
    const [fetchPreview, setFetchPreview] = useState(false);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [isPreviewLoading, setIsPreviewLoading] = React.useState(false);

    const updateReportName = (newReportName) => {
        setReportName(newReportName);
    };

    const updateDate = (newDate) => {
        setDate(newDate);
    };

    const updateDataSource = (newDataSource) => {
        setDataSource(newDataSource);
    };

    const updateSupplyPartnerID = (newSupplyPartnerID) => {
        setSupplyPartnerID(newSupplyPartnerID);
    };

    const updateSupplyTagID = (newTag) => {
        setSupplyTagID(newTag);
    };

    const updateFetchPreview = (newPreview) => {
        setFetchPreview(newPreview);
    }

    const updateInitialLoad = (newLoadComplete) => {
        setInitialLoad(newLoadComplete);
    }

    const updateIsPreviewLoading = (newIsPreviewLoading) => {
        setIsPreviewLoading(newIsPreviewLoading);
    }

    return (
        <IntegratedReportContext.Provider
            value={{
                reportName,
                date,
                dataSource,
                supplyPartnerID,
                supplyTagID,
                fetchPreview,
                initialLoad,
                isPreviewLoading,
                updateReportName,
                updateDate,
                updateDataSource,
                updateSupplyPartnerID,
                updateSupplyTagID,
                updateFetchPreview,
                updateInitialLoad,
                updateIsPreviewLoading,
            }}
        >
            {children}
        </IntegratedReportContext.Provider>
    );
};
