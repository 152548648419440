// utils/configDataMapper.js

import {isEmpty} from "../utils/helper";

export const mapApiDataToContext = (apiData) => {
    if (isEmpty(apiData)) return {};

    const config = apiData?.config;
    const meta = apiData?.meta;

    const generalData = {
        customer_name: config?.customer_details?.customer_name || "",
        channel_name: config?.customer_details?.channel_name || "",
        contacts: config?.customer_details?.contacts || [],
        platform_name: config?.platform_name || "",
        platform_amg_id: config?.amg_id || "",
        ts_channel_id: meta?.ts_channel_id || "",
        deal_type: config?.deal_type || "",
        ss_core_owner_supply_tag_id: config?.ss_core_owner_supply_tag_id || 0,
        ss_core_inventory_share: config?.ss_core_inventory_share || 0,
        net_floor_expectations: config?.net_floor_expectations || "",
        floor_rate: config?.floor_rate || "",
        additional_info_txt: config?.additional_info_txt || "",
        ads_plus_supply_partner_id: config?.ads_plus_supply_partner_id || "",
        connection_type: config?.connection_type || "",
        commission: config?.commission || "",
        ads_plus_prioritization: config?.ads_plus_prioritization || "",
        so_document_file_url: config?.so_document_file_url || "",
    };

    const onboardingFileData = {"config_data": [], "supply_data": []}

    const onboardingFileUrl = {
        onb_config_details_file_url: config?.onboarding_details?.onb_config_details_file_url || "",
        onb_supply_details_file_url: config?.onboarding_details?.onb_supply_details_file_url || "",
    };

    const otherDetails = {
        content_language: config?.onboarding_details?.supply_details?.content_language || "",
        content_descriptions: config?.onboarding_details?.supply_details?.content_descriptions || "",
        avg_pod_durations_in_sec: config?.onboarding_details?.supply_details?.avg_pod_durations_in_sec || 0,
        device_type: config?.onboarding_details?.supply_details?.device_type || [],
        inventory_available_in_geo: config?.onboarding_details?.supply_details?.inventory_available_in_geo || [],
        ad_placement_type: config?.onboarding_details?.supply_details?.ad_placement_type || "",
        is_supply_lda_content_compliant: config?.onboarding_details?.supply_details?.is_supply_lda_content_compliant || false,
        is_supply_coppa_inventory_enable: config?.onboarding_details?.supply_details?.is_supply_coppa_inventory_enable || false,
        ads_txt_content_url: config?.onboarding_details?.supply_details?.ads_txt_content_url || "",
        app_ads_txt_content_url: config?.onboarding_details?.supply_details?.app_ads_txt_content_url || "",
        seller_json_url: config?.onboarding_details?.supply_details?.seller_json_url || "",
        right_for_amagi_to_marketing: config?.onboarding_details?.demand_details?.right_for_amagi_to_marketing || false,
        direct_sales_team_availability: config?.onboarding_details?.demand_details?.direct_sales_team_availability || false,
        ad_quality_requirements: config?.onboarding_details?.demand_details?.ad_quality_requirements || "",
        political_policy_allowed: config?.onboarding_details?.demand_details?.political_policy_allowed || false,
        pharma_policy_allowed: config?.onboarding_details?.demand_details?.pharma_policy_allowed || false,
        gambling_policy_allowed: config?.onboarding_details?.demand_details?.gambling_policy_allowed || false,
        alcohol_policy_allowed: config?.onboarding_details?.demand_details?.alcohol_policy_allowed || false,
        cannabis_policy_allowed: config?.onboarding_details?.demand_details?.cannabis_policy_allowed || false,
        duplicate_creative_allowed: config?.onboarding_details?.demand_details?.duplicate_creative_allowed || false,
        duplicate_advertiser_domain_allowed: config?.onboarding_details?.demand_details?.duplicate_advertiser_domain_allowed || false,
        link_to_sale_collateral: config?.onboarding_details?.demand_details?.link_to_sale_collateral || "",
        ad_server_url: config?.onboarding_details?.technical_details?.ad_server_url || "",
        pre_fetching_ads: config?.onboarding_details?.technical_details?.pre_fetching_ads === true,
        pre_fetch_advance_time: config?.onboarding_details?.technical_details?.how_far_in_advance || "",
        ad_server_max_timeout: config?.onboarding_details?.technical_details?.ad_server_max_timeout || 0,
        // ad_category_restrictions: config?.onboarding_details?.
        positions_in_amagi_current_waterfall: config?.onboarding_details?.technical_details?.positions_in_amagi_current_waterfall || 1,
        ad_stitching_enable:  config?.onboarding_details?.technical_details?.ad_stitching_enable === true,
        stitching_vendor_name:  config?.onboarding_details?.technical_details?.stitching_vendor_name || "",
        ssai_vendor:  config?.onboarding_details?.technical_details?.ssai_vendor === true,
        ad_poding_enable:  config?.onboarding_details?.technical_details?.ad_poding_enable === "Dynamic" ? "true" : "false",
        multiple_ad_response_enable:  config?.onboarding_details?.technical_details?.multiple_ad_response_enable === true,
        supported_vast_versions:  config?.onboarding_details?.technical_details?.supported_vast_versions || "",
        max_creative_length:  config?.onboarding_details?.technical_details?.max_creative_length || 0,
        min_acceptable_bit_rate:  config?.onboarding_details?.technical_details?.min_acceptable_bit_rate || 0,
        max_acceptable_bit_rate:  config?.onboarding_details?.technical_details?.max_acceptable_bit_rate || 0,
        monthly_revenue: config?.onboarding_details?.partner_expectations?.monthly_revenue || "",
        net_ecpm: config?.onboarding_details?.partner_expectations?.net_ecpm || "",
        fill_rate: config?.onboarding_details?.partner_expectations?.fill_rate || "",
        any_other_kpis: config?.onboarding_details?.partner_expectations?.any_other_kpis || "",
        ad_category_restrictions:config?.onboarding_details?.demand_details?.ad_category_restrictions|| "",
        traffic:config?.onboarding_details?.technical_details?.traffic || "",
        sales_collateral_file_url: config?.onboarding_details?.demand_details?.sales_collateral_file_url || "",
        ad_domain_blocklist_file_url: config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url || "",
    };

    const supplyDetails = {
        dynamic_macros_list: config?.onboarding_details?.app_supply_details?.dynamic_macros_list || [],
        supply_tags: config?.onboarding_details?.app_supply_details?.supply_tags?.map(tag => ({
            inv_relationship: tag.inventory_relationship || "exclusive",
            app_name_in_app_store: tag?.app_name_in_app_store || "",
            channel_name_stream: tag?.channel_name_stream || "",
            operating_system: tag.operating_system || "",
            app_bundle_id: tag.app_bundle_id || 0,
            supply_label_id: tag?.supply_label_id || 0,
            device_type: tag?.device_type || ""
        })) || []
    };

    const supplyFormLabelData = supplyDetails.supply_tags;

    return {
        generalData,
        onboardingFileData,
        onboardingFileUrl,
        otherDetails,
        supplyDetails,
        supplyFormLabelData,
    };
};
