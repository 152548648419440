import React, {useEffect, useState, useRef, useCallback} from 'react';
import {CircularProgress, Grid} from '@mui/material';
import './index.css';
import {
    requestCustomerDetailsConfig,
    requestCustomerDetailsConfigNonTS
} from "../../RequestConfig/requestCustomerDetailsConfig";
import {
    requestSupplyDescriptionConfig,
    requestPartnershipExpectationsConfig,
    requestDemandInformationConfig,
    requestTechnicalDetailsConfig,
    requestDemandInformationConfigNonTS,
    requestPartnershipExpectationsConfigNonTS,
    requestSupplyDescriptionConfigNonTS,
    requestTechnicalDetailsConfigNonTS
} from "../../RequestConfig/requestOnboardingDetailsConfig";
import TableAppSupplyDetails from "../Tables/TableAppSupplyDetails";
import TableContactsDetails from "../Tables/TableRequestDetails/TableContactsDetails";
import TableDeviceDistribution from "../Tables/TableRequestDetails/TableDeviceDistribution";
import TableGeoDistribution from "../Tables/TableRequestDetails/TableGeoDistribution";
import {
    NA,
    NON_TS_PLATFORM_TYPE,
    TS_PLATFORM_TYPE,
    ADS_PLUS_DOWNLOAD_SERVICE, ERROR_NOTIFICATION_TYPE, BASIC_SENSITIVITY_CATEGORY_BLOCKLIST
} from "../../Constant";
import DownloadArrow from "../../assests/icons/DownloadArrow";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from "axios";
import {adsHeader, isEmpty} from "../../utils/helper";

function RequestDetailsBody({loading, Details, showNotification, userData}) {
    const supplyDescriptionRef = useRef(null);
    const demandInformationRef = useRef(null);
    const technicalDetailsRef = useRef(null);
    const partnershipExpectationsRef = useRef(null);
    const appSupplyDetailsRef = useRef(null);
    const [activeSection, setActiveSection] = useState("");
    const [downloadingFiles, setDownloadingFiles] = useState({});
    const [mouseEntered, setMouseEntered] = useState(false);

    const handleDownloadGcsFile = useCallback(async (fileId) => {
        if (downloadingFiles[fileId]) return;
        setDownloadingFiles(prevState => ({ ...prevState, [fileId]: true }));
        let fileName = fileId.split('/').pop();
        const uri = `${ADS_PLUS_DOWNLOAD_SERVICE}get-file-details/?file_id=`;
        const url = uri + fileId;
        const headers = adsHeader();

        try {
            const response = await axios.get(url, {
                headers: headers,
                responseType: 'blob',
            });

            if (response.status === 200) {
                const contentType = response.headers['content-type'] || 'application/octet-stream';
                const blob = new Blob([response.data], { type: contentType });
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } else {
                showNotification('Failed to download file.', ERROR_NOTIFICATION_TYPE)
                console.error('Failed to download file. Status:', response.status);
            }
        } catch (error) {
            showNotification('Error downloading the file', ERROR_NOTIFICATION_TYPE)
            console.error('Error downloading the file', error);
        } finally {
            setDownloadingFiles(prevState => ({ ...prevState, [fileId]: false }));
        }
    }, [downloadingFiles, showNotification, userData?.user_email]);

    const handleTxtFileClick = (url) => () => {
        if (url) {
            if (!/^https?:\/\//i.test(url)) {
                url = 'http://' + url;
            }
            const newWindow = window.open(url, '_blank');
            if (newWindow) {
                newWindow.opener = null;
                newWindow.rel = 'noopener noreferrer';
            }
        }
    }

    const handleHover = (toggle) => {
        setMouseEntered(toggle);
    }

    const getFieldData = (field) => {
        let detailsData;
        switch (field.map_key) {
            case "meta":
                detailsData = Details?.meta;
                break;
            case "customer_details":
                detailsData = Details?.config?.customer_details;
                break;
            case "demand_details":
            case "supply_details":
            case "app_supply_details":
            case "technical_details":
            case "partner_expectations":
                detailsData = Details?.config?.onboarding_details?.[field.map_key];
                break;
            case "config":
            default:
                detailsData = Details?.config;
                break;
        }
        if (!detailsData) return NA;

        const value = detailsData[field.key];
        if (value === undefined || value === null) return NA;

        switch (field.type) {
            case "text":
                if (typeof value === "boolean" && field.bool_type === "yes_no") {
                    return value ? "Yes" : "No";
                } else if (typeof value === "boolean") {
                    return value ? "Allowed" : "Never Allowed";
                }
                if (field.suffix && value !== "") {
                    return `${value} ${field.suffix}`;
                }
                if (field.prefix && value !== "") {
                    return `${field.prefix} ${value}`;
                }
                return value !== "" ? value : NA;

            case "table_device_distribution":
            case "table_geo_distribution":
                return value || [];
            case "download":
                return (
                    (value &&
                        <div className="download-links" onClick={() => handleDownloadGcsFile(value)}>
                            <span>{value.split('/').pop()}</span>
                            { !downloadingFiles[value] && <DownloadArrow /> }
                            { downloadingFiles[value] && <CircularProgress size={18} />}
                        </div>
                    ) || NA
                );
            case "doc_link":
                return (
                    (value && value !== "" &&
                        <div className="download-links" onClick={handleTxtFileClick(value)}>
                            {value}
                        </div>
                    ) || NA
                );
            case "text_with_info":
                return (
                    <div>
                        <div> {value || NA }</div>
                        { value === "basic_sensitivity_category_blocking" && (
                            <React.Fragment>
                                <div
                                    className="text_with_info"
                                >
                                    <InfoOutlinedIcon
                                        onMouseEnter={() => handleHover(true)}
                                        onMouseLeave={() => handleHover(false)}
                                        sx={{ fontSize: 18 }}
                                    />
                                    IAB Categories
                                </div>
                                <div className={`pop-over-div ${mouseEntered ? 'visible' : 'hidden'}`}>
                                    {BASIC_SENSITIVITY_CATEGORY_BLOCKLIST.map((category, index) => (
                                        <div key={index}>{category}</div>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                );
            default:
                return NA;
        }
    };


    const TextField = ({field, index}) => {
        if (field.key === ("commission") && isEmpty(Details?.config?.deal_type)) return null;
        if (field.key === ("ss_core_inventory_share") && isEmpty(Details?.config?.deal_type)) return null;
        if (field.key === "ss_core_inventory_share" && Details?.config?.deal_type === "revenue") return null;
        if (field.key === "ss_core_inventory_share" && Details?.config?.deal_type === "fallback") return null;
        if (field.key === "commission" && Details?.config?.deal_type === "inventory") return null;

        return(
            <Grid container key={index} columnSpacing={1}>
                <Grid item xs={8} md={2} className="request-details-table-title">
                    {field.label}
                </Grid>
                <Grid item xs={8} md={6} className="request-details-table-value">
                    {getFieldData(field)}
                </Grid>
            </Grid>
        )
    };

    const sections = [
        {label: "SUPPLY DESCRIPTION", ref: supplyDescriptionRef, id: 'supply-description'},
        {label: "DEMAND INFORMATION", ref: demandInformationRef, id: 'demand-information'},
        {label: "TECHNICAL DETAILS", ref: technicalDetailsRef, id: 'technical-details'},
        {label: "PARTNERSHIP EXPECTATIONS", ref: partnershipExpectationsRef, id: 'partnership-expectations'},
        {label: "APP SUPPLY DETAILS", ref: appSupplyDetailsRef, id: 'app-supply-details'},
    ];

    const handleNavClick = (ref) => {
        if (ref.current) {
            const topOffset = 75;
            const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - topOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });

            setActiveSection(ref.current.id);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            {rootMargin: '0px 0px -50% 0px', threshold: 0.5}
        );

        sections.forEach((section) => {
            if (section.ref.current) {
                section.ref.current.id = section.id;
                observer.observe(section.ref.current);
            }
        });

        return () => {
            sections.forEach((section) => {
                if (section.ref.current) {
                    observer.unobserve(section.ref.current);
                }
            });
        };
    }, [sections]);

    return (
        !loading && Details?.type === TS_PLATFORM_TYPE ? (
            <React.Fragment>
                <div className="detail-type-name">Customer details</div>
                {requestCustomerDetailsConfig.map((field, index) => (
                    field.type === "text" ? (
                        <TextField field={field} index={index}/>
                    ) : field.type === "sub_heading" ? (
                        <div className="internal_detail-type-name">{field.label}</div>
                    ) : field.type === "download" ? (
                        <Grid container key={index} columnSpacing={1}>
                            <Grid item xs={8} md={2} className="request-details-table-title">
                                {field.label}
                            </Grid>
                            <Grid item xs={8} md={6} className="request-details-table-value">
                                {getFieldData(field)}
                            </Grid>
                        </Grid>
                    ) : (
                        field.type === "table_contacts" && (
                            <Grid container columnSpacing={1} key={index}>
                                <Grid item xs={8} md={2} className="request-details-table-title">
                                    Contacts
                                </Grid>
                                <Grid item xs={8} md={5} className="request-details-table-value">
                                    <TableContactsDetails
                                        ContactsData={Details?.config?.customer_details?.contacts || []}/>
                                </Grid>
                            </Grid>
                        )
                    )
                ))}
                <div className="detail-type-name onboarding-details">Onboarding details</div>
                <Grid container className="other-details-grid">
                    <Grid item xs={8} className="req-left-side-form">
                        <div className="sub-detail-type-name" ref={supplyDescriptionRef}>SUPPLY DESCRIPTION</div>
                        {requestSupplyDescriptionConfig.map((field, index) => (
                            <React.Fragment key={index}>
                                {field.type === "text" && <TextField field={field} index={index}/>}
                                {field.type === "table_device_distribution" && (
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={4} className="request-details-table-value">
                                            <TableDeviceDistribution
                                                DeviceData={Details?.config?.onboarding_details?.supply_details?.device_type || []}/>
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "table_geo_distribution" && (
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={4} className="request-details-table-value">
                                            <TableGeoDistribution
                                                GeoData={Details?.config?.onboarding_details?.supply_details?.inventory_available_in_geo || []}/>
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "doc_link" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={6} className="request-details-table-value">
                                            {getFieldData(field)}
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={demandInformationRef}>DEMAND INFORMATION</div>
                        {requestDemandInformationConfig.map((field, index) => (
                            <React.Fragment key={index}>
                                {field.type === "text" && <TextField field={field} index={index}/>}
                                {field.type === "doc_link" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={6} className="request-details-table-value">
                                            {getFieldData(field)}
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "link_or_download" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        {!isEmpty(Details.config.onboarding_details.demand_details.sales_collateral_file_url) ? (
                                            <Grid item xs={8} md={6} className="request-details-table-value">
                                                <div key={index} className="download-links"
                                                     onClick={() => handleDownloadGcsFile(Details.config.onboarding_details.demand_details.sales_collateral_file_url)}>
                                                    <span>{Details.config.onboarding_details.demand_details.sales_collateral_file_url.split('/').pop()}</span>
                                                    { !downloadingFiles[Details.config.onboarding_details.demand_details.sales_collateral_file_url] && <DownloadArrow /> }
                                                    { downloadingFiles[Details.config.onboarding_details.demand_details.sales_collateral_file_url] && <CircularProgress size={18} />}
                                                </div>
                                            </Grid>
                                        ) : !isEmpty(Details.config.onboarding_details.demand_details.link_to_sale_collateral) ? (
                                            <Grid item xs={8} md={6} className="request-details-table-value">
                                                <div className="download-links" onClick={handleTxtFileClick(Details.config.onboarding_details.demand_details.link_to_sale_collateral)}>
                                                    {Details.config.onboarding_details.demand_details.link_to_sale_collateral}
                                                </div>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={8} md={6} className="request-details-table-value">
                                                {NA}
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                                {field.type === "text_with_info" &&
                                    <React.Fragment>
                                        {field.type === "text_with_info" && <TextField field={field} index={index}/>}
                                        { Details?.config?.onboarding_details?.demand_details?.ad_quality_requirements === "ad_domain_blocklist" && (
                                            <Grid container key={index} columnSpacing={1}>
                                                <Grid item xs={8} md={2} className="request-details-table-title">
                                                    Blocklist
                                                </Grid>
                                                <Grid item xs={8} md={6} className="request-details-table-value">
                                                    { !isEmpty(Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url) ? (<div key={index} className="download-links"
                                                         onClick={() => handleDownloadGcsFile(Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url)}>
                                                        <span>{Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url.split('/').pop()}</span>
                                                            { !downloadingFiles[Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url] && <DownloadArrow /> }
                                                            { downloadingFiles[Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url] && <CircularProgress size={18} />}
                                                    </div>)
                                                    : NA}
                                                </Grid>
                                            </Grid>
                                            )
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={technicalDetailsRef}>TECHNICAL DETAILS</div>
                        {requestTechnicalDetailsConfig.map((field, index) => (
                            field.type === "text" && <TextField field={field} index={index}/>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={partnershipExpectationsRef}>PARTNERSHIP
                            EXPECTATIONS
                        </div>
                        {requestPartnershipExpectationsConfig.map((field, index) => (
                            field.type === "text" && <TextField field={field} index={index}/>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={appSupplyDetailsRef}>
                            APP SUPPLY DETAILS
                        </div>
                        <Grid item xs={12} md={10} className="app-supply-table">
                            <TableAppSupplyDetails
                                tableData={Details?.config?.onboarding_details?.app_supply_details?.supply_tags}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} className="sticky-nav">
                        {sections.map((section, index) => (
                            <div
                                key={index}
                                className={`nav-item ${activeSection === section.id ? "active" : ""}`}
                                onClick={() => handleNavClick(section.ref)}
                            >
                                {section.label}
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </React.Fragment>
        ) : Details?.type === NON_TS_PLATFORM_TYPE && (
            <React.Fragment>
                <div className="detail-type-name">Customer details</div>
                {requestCustomerDetailsConfigNonTS.map((field, index) => (
                        field.type === "text" ? (
                            <TextField field={field} index={index}/>
                        ) : field.type === "download" ? (
                            <TextField field={field} index={index}/>
                        ) : field.type === "sub_heading" ? (
                            <div className="internal_detail-type-name">{field.label}</div>
                        ) : field.type === "table_contacts" ? (
                            <Grid container columnSpacing={1} key={index}>
                                <Grid item xs={8} md={2} className="request-details-table-title">
                                    Contacts
                                </Grid>
                                <Grid item xs={8} md={4} className="request-details-table-value">
                                    <TableContactsDetails ContactsData={Details?.config?.customer_details?.contacts || []}/>
                                </Grid>
                            </Grid>
                        ) : (<></>)
                    )
                )}
                <div className="detail-type-name onboarding-details">Onboarding details</div>
                <Grid container className="other-details-grid">
                    <Grid item xs={8} lg={10} className="req-left-side-form">
                        <div className="sub-detail-type-name" ref={supplyDescriptionRef}>SUPPLY DESCRIPTION</div>
                        {requestSupplyDescriptionConfigNonTS.map((field, index) => (
                            <React.Fragment key={index}>
                                {field.type === "text" && <TextField field={field} index={index}/>}
                                {field.type === "table_device_distribution" && (
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={4} className="request-details-table-value">
                                            <TableDeviceDistribution
                                                DeviceData={Details?.config?.onboarding_details?.supply_details?.device_type || []}/>
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "table_geo_distribution" && (
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={4} className="request-details-table-value">
                                            <TableGeoDistribution
                                                GeoData={Details?.config?.onboarding_details?.supply_details?.inventory_available_in_geo || []}/>
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "doc_link" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={6} className="request-details-table-value">
                                            {getFieldData(field)}
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={demandInformationRef}>DEMAND INFORMATION</div>
                        {requestDemandInformationConfigNonTS.map((field, index) => (
                            <React.Fragment key={index}>
                                {field.type === "text" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={6} className="request-details-table-value">
                                            {getFieldData(field)}
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "doc_link" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={8} md={6} className="request-details-table-value">
                                            {getFieldData(field)}
                                        </Grid>
                                    </Grid>
                                )}
                                {field.type === "link_or_download" && (
                                    <Grid container key={index} columnSpacing={1}>
                                        <Grid item xs={8} md={2} className="request-details-table-title">
                                            {field.label}
                                        </Grid>
                                        {!isEmpty(Details.config.onboarding_details.demand_details.sales_collateral_file_url) ? (
                                            <Grid item xs={8} md={6} className="request-details-table-value">
                                                <div key={index} className="download-links"
                                                     onClick={() => handleDownloadGcsFile(Details.config.onboarding_details.demand_details.sales_collateral_file_url)}>
                                                    <span>{Details.config.onboarding_details.demand_details.sales_collateral_file_url.split('/').pop()}</span>
                                                    { !downloadingFiles[Details.config.onboarding_details.demand_details.sales_collateral_file_url] && <DownloadArrow /> }
                                                    { downloadingFiles[Details.config.onboarding_details.demand_details.sales_collateral_file_url] && <CircularProgress size={18} />}
                                                </div>
                                            </Grid>
                                        ) : !isEmpty(Details.config.onboarding_details.demand_details.link_to_sale_collateral) ? (
                                            <Grid item xs={8} md={6} className="request-details-table-value">
                                                <div className="download-links" onClick={handleTxtFileClick(Details.config.onboarding_details.demand_details.link_to_sale_collateral)}>
                                                    {Details.config.onboarding_details.demand_details.link_to_sale_collateral}
                                                </div>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={8} md={6} className="request-details-table-value">
                                                {NA}
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                                {field.type === "text_with_info" &&
                                    <React.Fragment>
                                        {field.type === "text_with_info" && <TextField field={field} index={index}/>}
                                        { Details?.config?.onboarding_details?.demand_details?.ad_quality_requirements === "ad_domain_blocklist" && (
                                            <Grid container key={index} columnSpacing={1}>
                                                <Grid item xs={8} md={2} className="request-details-table-title">
                                                    Blocklist
                                                </Grid>
                                                <Grid item xs={8} md={6} className="request-details-table-value">
                                                    { !isEmpty(Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url) ? (
                                                        <div key={index} className="download-links" onClick={() => handleDownloadGcsFile(Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url)}>
                                                            <span>{Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url.split('/').pop()}</span>
                                                            { !downloadingFiles[Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url] && <DownloadArrow /> }
                                                            { downloadingFiles[Details?.config?.onboarding_details?.demand_details?.ad_domain_blocklist_file_url] && <CircularProgress size={18} />}
                                                        </div>
                                                        ) : NA}
                                                </Grid>
                                            </Grid>
                                        )
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={technicalDetailsRef}>TECHNICAL DETAILS</div>
                        {requestTechnicalDetailsConfigNonTS.map((field, index) => (
                            field.type === "text" && <TextField field={field} index={index}/>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={partnershipExpectationsRef}>PARTNERSHIP
                            EXPECTATIONS
                        </div>
                        {requestPartnershipExpectationsConfigNonTS.map((field, index) => (
                            field.type === "text" && <TextField field={field} index={index}/>
                        ))}
                        <div className="tab-divider"></div>
                        <div className="sub-detail-type-name" ref={appSupplyDetailsRef}>APP SUPPLY DETAILS</div>
                        <Grid item xs={10} md={10} className="app-supply-table">
                            <TableAppSupplyDetails
                                tableData={Details?.config?.onboarding_details?.app_supply_details?.supply_tags}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} lg={2} className="sticky-nav">
                        {sections.map((section, index) => (
                            <div
                                key={index}
                                className={`nav-item ${activeSection === section.id ? "active" : ""}`}
                                onClick={() => handleNavClick(section.ref)}
                            >
                                {section.label}
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    );
}

export default RequestDetailsBody;
