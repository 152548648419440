import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import DropInputOnboarding from "../../../Common/DropInputOnboarding";
import { RequestContext } from "../../../Context/RequestContext";
import {
    NON_TS_SAMPLE_FILE_URL,
    SUCCESS_NOTIFICATION_TYPE, SUPPLY_APP_DATA_MAX_LIMIT,
    SUPPLY_LIST_SAMPLE_FILE_URL, TS_SAMPLE_FILE_URL,
    WARNING_NOTIFICATION_TYPE
} from "../../../Constant";
import { convertValueToBoolean } from "../../../utils/helper";
import {RequestSupplyDetailTable} from "../../../FormConfig/requestSupplyDetailTable";
import {DEVICE_DISTRIBUTION_XL, GEO_DISTRIBUTION_XL} from "../../../Constant";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";

function OnbConfigFile(props) {
    const [onboardingConfigFileData, setOnboardingConfigFileData] = useState({});
    const [fileDataDropInput, setFileDataDropInput] = useState({ "config_data": {}, "supply_data": [] });
    const {
        updateOnboardingFileData,
        updateOnboardingFileUrl,
        updateOtherDetails,
        updateSupplyDetails,
        updateSupplyLabelFormData,
        onboardingFileUrl
    } = useContext(RequestContext);

    const handleFileChangeDropInput = async (id = "", file = {}, key = "") => {
        if(file){
            const getStateOnboardingData = { ...onboardingConfigFileData };
            const status = await useWebStorageHelper.setFileItem(file, key);

            if (status) {
                getStateOnboardingData[key] = file?.name;
                setOnboardingConfigFileData(getStateOnboardingData);
                updateOnboardingFileUrl(getStateOnboardingData);
            } else {
                props.showNotification(WARNING_NOTIFICATION_TYPE, "File Missed");
            }
        }else {
            const getStateOnboardingData = { ...onboardingConfigFileData };
            getStateOnboardingData[key] = "";
            updateOnboardingFileUrl(getStateOnboardingData);
            props.showNotification(SUCCESS_NOTIFICATION_TYPE, "File Deleted");
        }
    };

    const updateOtherDetailsFromXlsx = (xlsxData) => {
        const updatedDetails = {
            device_type: [],
            inventory_available_in_geo: []
        };

        xlsxData.forEach(([label, value]) => {
            if (label && value) {
                // Check if the label matches any in DEVICE_DISTRIBUTION_XL
                if (DEVICE_DISTRIBUTION_XL.hasOwnProperty(label)) {
                    updatedDetails.device_type.push({
                        percentage: value,
                        type: DEVICE_DISTRIBUTION_XL[label],
                    });
                }
                // Check if the label matches any in GEO_DISTRIBUTION_XL
                else if (GEO_DISTRIBUTION_XL.hasOwnProperty(label)) {
                    updatedDetails.inventory_available_in_geo.push({
                        percentage: value,
                        region_key: GEO_DISTRIBUTION_XL[label],
                        region_name: GEO_DISTRIBUTION_XL[label],
                    });
                }
                // Handle other cases with select options
                else {
                    const configItem = props.OtherformConfig.find(
                        (item) => item?.label?.toLowerCase() === label?.toLowerCase()
                    );

                    if (configItem) {
                        // Handle select dropdowns
                        if (configItem.type === 'select') {
                            const selectedOption = configItem.options.find(
                                (option) => option.label.toLowerCase() === value.toLowerCase()
                            );

                            if (selectedOption) {
                                updatedDetails[configItem.key] = selectedOption.value;
                            } else {
                                console.warn(`No matching option found for label: ${label}`);
                            }
                        } else {
                            const convertedValue = convertValueToBoolean(value);
                            updatedDetails[configItem.key] = convertedValue;
                        }
                    }
                }
            }
        });

        // Update the form data in the context
        updateOtherDetails(updatedDetails);
    };

    const updateSupplyDetailsFromXlsx = (xlsxData) => {
        try{
            const updatedSupplyDetails = [];
            const updateSupplyLabelData = []
            let rowsProcessed = 0;
            // Extract headers
            const headers = xlsxData[0];
            // Assuming first row is headers and should be skipped
            for (let i = 1; i < xlsxData.length; i++) {
                if (rowsProcessed >= SUPPLY_APP_DATA_MAX_LIMIT) break;  // Stop after processing 10 rows with data
                const row = xlsxData[i];
                const hasValues = row.some(value => value !== null && value !== undefined && value !== "");
                if (hasValues) {
                    const rowData = {};
                    const labelData = {}
                    row.forEach((value, colIndex) => {
                        const header = headers[colIndex];
                        // Find the corresponding config item
                        const configItem = RequestSupplyDetailTable.find(item => item?.mappedKey ? item?.mappedKey?.toLowerCase() === header.toLowerCase() : item?.heading?.toLowerCase() === header.toLowerCase());
                        if (configItem) {
                            if (configItem.type === "select") {
                                const selectedOption = configItem.options.find(option => option.label.toLowerCase() === value.toLowerCase());
                                if (selectedOption) {
                                    labelData[configItem.key] = selectedOption?.label;
                                    rowData[configItem.key] = selectedOption.value;
                                } else {
                                    console.warn(`Error in filling app supply details`);
                                }
                            } else {
                                rowData[configItem.key] = value;
                            }
                        }
                    });
                    updatedSupplyDetails.push(rowData);
                    updateSupplyLabelData.push(labelData)
                    rowsProcessed++;
                }
            }
            updateSupplyDetails({"supply_tags": updatedSupplyDetails});
            updateSupplyLabelFormData(updateSupplyLabelData)
        } catch (e) {
            props.showNotification(WARNING_NOTIFICATION_TYPE, "Error in supply file, please refer to sample file");
            useWebStorageHelper.removeItem("onb_supply_details_file_url");
            const getStateOnboardingData = { ...onboardingConfigFileData };
            getStateOnboardingData["onb_supply_details_file_url"] = "";
            updateOnboardingFileUrl(getStateOnboardingData);
            // Reset the DropInputOnboarding component to ensure invalid files are not displayed
            if (props.onFileChange) {
                props.onFileChange("supply", null, "onb_supply_details_file_url");
            }
        }
    };

    const handleDataLoadedDropInput = (key = "", fileName = "", data = {}) => {
           const getStateFormData = { ...fileDataDropInput };

           if (key === "config") {
               getStateFormData["config_data"] = data;
               // Update the form with the XLSX data
               updateOtherDetailsFromXlsx(data);
           } else if (key === "supply") {
               getStateFormData["supply_data"] = data;
               updateSupplyDetailsFromXlsx(data);
           }
           setFileDataDropInput(getStateFormData);
           updateOnboardingFileData(getStateFormData);
    };

    return (
        <div className="onboarding-form-div">
            <DropInputOnboarding
                id="config"
                fileType="Only xls, xlsx"
                subMessage=""
                headMessage="Configuration file"
                onFileChange={handleFileChangeDropInput}
                onDataLoaded={handleDataLoadedDropInput}
                formKey="onb_config_details_file_url"
                supportedType=".xls,.xlsx"
                sampleFileUrl={ props?.type === "TS" ? TS_SAMPLE_FILE_URL : NON_TS_SAMPLE_FILE_URL}
                existingFileUrl={onboardingFileUrl?.onb_config_details_file_url || null}
                showNotification={props?.showNotification}

            />
            <DropInputOnboarding
                id="supply"
                fileType="Only xls, xlsx"
                subMessage=""
                headMessage="Supply file"
                onFileChange={handleFileChangeDropInput}
                onDataLoaded={handleDataLoadedDropInput}
                formKey="onb_supply_details_file_url"
                supportedType=".xls,.xlsx"
                sampleFileUrl={SUPPLY_LIST_SAMPLE_FILE_URL}
                existingFileUrl={onboardingFileUrl?.onb_supply_details_file_url || null}
                showNotification={props?.showNotification}
            />
        </div>
    );
}

OnbConfigFile.propTypes = {
    showNotification: PropTypes.func.isRequired,
    OtherformConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OnbConfigFile;
