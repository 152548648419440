import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";


const getAllSupplyPartnersBySourceAndDate = async (data_source, date) => {
    try {
        const query = `
                    query MyQuery{
                          all_supply_partners_by_source_and_date(where: {data_source: "${data_source}", date: "${date}"}) {
                            supply_partner_id
                            supply_partner_name
                          }
                        } `;

        const response = await axios.post(REPORT_BFF_URI, { query });
        return response.data.data.all_supply_partners_by_source_and_date;
    } catch (error) {
        console.error("Error fetching Supply Partner and Tag for selected data source:", error);
        return null;
    }
};

export default getAllSupplyPartnersBySourceAndDate;
