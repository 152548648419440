import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";

const getMacrosByIdIntegrated = async (ads_supply_tag_id, core_supply_tag_id, delivery_id) => {
    try {
        const query = `
                    query MyQuery {
                          getMacrosById(
                            where: {ads_supply_tag_id: "${ads_supply_tag_id}", core_supply_tag_id: "${core_supply_tag_id}", delivery_id: "${delivery_id}"}
                          ) {
                            adsplus {
                              macro_key
                              macro_value
                            }
                            core {
                              macro_key
                              macro_value
                            }
                            incoming {
                              macro_key
                              macro_value
                            }
                          }
                        }`;

        const response = await axios.post(REPORT_BFF_URI, { query });
        return response.data.data.getMacrosById;
    } catch (error) {
        console.error("Error fetching Macros:", error);
        return null;
    }
};

export default getMacrosByIdIntegrated;
