import React from 'react';
import "./index.css";
import {Grid, TableCell, TableHead, TableRow, Table, TableBody, Box, Tooltip, Typography, Skeleton} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import getMacrosByIdIntegrated from "../../../api/getMacrosByIdIntegrated";
import {LoadingScreen} from "../../CommonScreens/LoadingScreen";
import {EmptyScreen} from "../../CommonScreens/EmptyScreen";

const IntegratedReportTableInsights = ( props) => {

    const [macros, setMacros] = React.useState([]);
    const [maxRows, setMaxRows] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const handleCloseDrawerStateUpdate = () =>{
        props.handleCloseDrawer();
    }

    React.useEffect(() => {
        async function fetchMacrosData() {
            try {
                setLoading(true);
                const macrosData = await getMacrosByIdIntegrated(
                    props?.selectedRow?.ads_supply_tag_id ? props?.selectedRow?.ads_supply_tag_id : '',
                    props?.selectedRow?.core_supply_tag_id ? props?.selectedRow?.core_supply_tag_id : '',
                    props?.selectedRow?.delivery_id ? props?.selectedRow?.delivery_id : ''
                );
                setMacros(macrosData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching macros data:", error);
            }
        }
            fetchMacrosData();
    }, [props.selectedRow.ads_supply_tag_id, props.selectedRow.core_supply_tag_id, props.selectedRow.delivery_id]);

// Calculating maxRows when macros data is updated
    React.useEffect(() => {
        if (macros && Object.keys(macros).length > 0) {
            const incomingLength = macros.incoming ? macros.incoming.length : 0;
            const coreLength = macros.core ? macros.core.length : 0;
            const adsplusLength = macros.adsplus ? macros.adsplus.length : 0;

            setMaxRows(Math.max(incomingLength, coreLength, adsplusLength));
        } else {
            setMaxRows(0);
        }
    }, [macros]);


    return (
        <React.Fragment>
                    <Grid item xs={12} className="table-frame">
                        <Grid container xs={12}>
                            <Grid item xs={0.5} >
                                <Typography className="table-header macro-table-heading">
                                    <ArrowBackIcon className="integrated-arrowBack-icon" onClick={(_) => handleCloseDrawerStateUpdate()}/>
                                </Typography>
                            </Grid>

                            <Grid item xs={11.5} className="table-info">
                                <Typography className="core-heading"> SS-core Supply tag</Typography>
                                <Typography className="table-header macro-table-heading">
                                    {props.selectedRow?.core_supply_tag_name}
                                </Typography>

                                <Grid container className="table-heading-subGrid" direction="column">
                                    <Grid item xs={4.5}>
                                        <Typography className="core-heading"> SS-ads+ Supply tag</Typography>
                                        <Typography className="core-heading-value">
                                            {props.selectedRow?.ads_supply_tag_name}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1.5}>
                                        <Typography className="core-heading"> SS Core ad requests</Typography>
                                        <Typography className="core-heading-value">
                                            {props.selectedRow?.core_total_ad_requests }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography className="core-heading"> SS-ads+ Ad requests</Typography>
                                        <Typography className="core-heading-value">
                                            {props.selectedRow?.ads_total_ad_requests }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography className="core-heading">SS Core impression</Typography>
                                        <Typography className="core-heading-value">
                                            {props.selectedRow?.core_total_impressions }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography className="core-heading"> SS-ads+ impressions</Typography>
                                        <Typography className="core-heading-value">
                                            {props.selectedRow?.ads_total_impressions }
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>

                        <Table className="report-table" stickyHeader aria-label="sticky table">

                            <TableHead className="report-table-head">
                                <TableRow>
                                    <TableCell colSpan={3} className="macro-heading">INCOMING ({macros?.incoming  ? macros?.incoming.length : <Skeleton animation="wave" className="skleton-loading-screen skeleton-display" width="2%" height={20} />})</TableCell>
                                    <TableCell colSpan={2} className="macro-heading custom-border">SS CORE ({macros?.core ? macros?.core.length : <Skeleton animation="wave" className="skleton-loading-screen skeleton-display" width="2%" height={20} />})</TableCell>
                                    <TableCell colSpan={2} className="macro-heading">SS ADS+ ({macros?.adsplus ? macros?.adsplus.length : <Skeleton animation="wave" className="skleton-loading-screen skeleton-display" width="2%" height={20} />})</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>MACRO</TableCell>
                                    <TableCell>VALUE</TableCell>
                                    <TableCell>SOURCE</TableCell>
                                    <TableCell className="custom-border-left">MACRO</TableCell>
                                    <TableCell className="custom-border-right">VALUE</TableCell>
                                    <TableCell>MACRO</TableCell>
                                    <TableCell>VALUE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ?
                                    <LoadingScreen rowsNumber={7}/>
                                    : !loading && (macros && macros.length === 0) ?
                                        <TableRow>

                                            <TableCell colspan={13}> <EmptyScreen message="No results found"/> </TableCell>

                                        </TableRow>
                                    :
                                    <React.Fragment>
                                        {[...Array(maxRows)].map((row, index) => (
                                            <TableRow key={index}>
                                                {/* INCOMING Data */}
                                                <Tooltip className="tooltip-arrow" title={macros.incoming[index]?.macro_key || ''} arrow >
                                                    <TableCell className="integrated-tableCell macro-key-width">{macros.incoming[index]?.macro_key || ''}</TableCell>
                                                </Tooltip>
                                                <Tooltip className="tooltip-arrow" title={macros.incoming[index]?.macro_value || ''} arrow >
                                                    <TableCell className="integrated-tableCell macro-value-width">{macros.incoming[index]?.macro_value || ''}</TableCell>
                                                </Tooltip>
                                                <TableCell className="integrated-tableCell macro-source-width">Player</TableCell>

                                                {/* SS CORE Data */}

                                                <Tooltip className="tooltip-arrow" title={macros.core[index]?.macro_key || ''} arrow >
                                                <TableCell className="custom-border-left integrated-tableCell macro-key-width">{macros.core[index]?.macro_key || ''}</TableCell>
                                                </Tooltip>
                                                <Tooltip className="tooltip-arrow" title={macros.core[index]?.macro_value || ''} arrow >
                                                <TableCell className="custom-border-right integrated-tableCell macro-value-width">{macros.core[index]?.macro_value || ''}</TableCell>
                                                </Tooltip>

                                                {/* SS ADS+ Data */}
                                                <Tooltip className="tooltip-arrow" title={macros.adsplus[index]?.macro_key || ''} arrow >
                                                <TableCell className="integrated-tableCell macro-key-width">{macros.adsplus[index]?.macro_key || ''}</TableCell>
                                                </Tooltip>
                                                <Tooltip className="tooltip-arrow" title={macros.adsplus[index]?.macro_value || ''} arrow >
                                                <TableCell className="integrated-tableCell macro-value-width">{macros.adsplus[index]?.macro_value || ''}</TableCell>
                                                </Tooltip>

                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                }
                            </TableBody>

                        </Table>
                    </Grid>
        </React.Fragment>
    )
}

export default IntegratedReportTableInsights
